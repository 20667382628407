body {
    /* background-color: #BBE488; */
    background-color:  #f4f5f8;
}
.main-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    /* background: url(../assets/breadhound/breadhound-splash2.svg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
}
.app-info-top {
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding-top: 50px;
}
.icon {
    width: 80px;
    height: 80px;
    padding: 10px;
}
.logo {
    width: 180px;
    padding: 10px;
}
.app-info-text {
    width: 80%;
    max-width: 600px;
}
.app-info-text p{
    font-size: 18px;
}
.app-info-screenshots {
    width: 100%;
    max-width: 500px;
}
.app-info-download {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}
.badge {
    height: 40px;
    margin: 10px;
}
.screenshots {
    width: 100%;
}