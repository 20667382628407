body {
    /* background-color: #BBE488; */
    background-color:  #f4f5f8;
}
.main-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}
.app-info-top {
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding-top: 50px;
}
.icon {
    width: 80px;
    height: 80px;
    padding: 10px;
}
.logo {
    width: 180px;
    padding: 10px;
}
.policy-container {
    width: 80%;
    max-width: 650px;
}
.policy-header {

}
.policy-body {

}