.Page-container {
  background: linear-gradient(330deg, #051937, #051c3b, #062040, #062344, #062b4e, #053457, #033c61, #00456a);
  background-size: 1000% 1000%;
  -webkit-animation: gradient-animation 30s ease infinite;
  -moz-animation: gradient-animation 30s ease infinite;
  -o-animation: gradient-animation 30s ease infinite;
  animation: gradient-animation 30s ease infinite;
}

@-webkit-keyframes gradient-animation {
  0%{background-position:51% 0%}
  50%{background-position:50% 100%}
  100%{background-position:51% 0%}
}
@-moz-keyframes gradient-animation {
  0%{background-position:51% 0%}
  50%{background-position:50% 100%}
  100%{background-position:51% 0%}
}
@-o-keyframes gradient-animation {
  0%{background-position:51% 0%}
  50%{background-position:50% 100%}
  100%{background-position:51% 0%}
}
@keyframes gradient-animation {
  0%{background-position:51% 0%}
  50%{background-position:50% 100%}
  100%{background-position:51% 0%}
}

.Body-container{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    min-height: 100vh;
    color: aliceblue;
    z-index: 100;
    overflow: hidden;
  }
  .Left-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
    width: 50%;
    padding: 15px;
    /* background-color: yellow; */
  }
  .Inner-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    max-width: 90%;
    
  }
  .Button-group{
    display: inline;
    margin-top: 25px;
  }
  .Breadhound-icon{
    width: 35px;
    margin-left: 10px;
  }
  .Header-text {
    color: aliceblue;
  }
  .Sub-text {
    width: 450px;
    max-width: 95%;
    margin: 0;
    text-align: left;
  }
  .App-link {
    color: #61dafb;
  }
  .Lumaki-logo {
    width: 250px;
    margin-bottom: 10px;
  }
  .Right-container {
    /* max-height: 100vh; */
    padding-bottom: 45px;
    width: 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .Rocket-container {
    height: 500px;
    animation: vibration 0.2s infinite;
  }
  .Rocket {
    position: absolute;
    height: 500px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .Exhaust {
    position: absolute;
    bottom: -30px;
    width: 50px;
    left: 50%;
    transform: translate(-50%,0);
    animation: main-fire 0.1s ease-in infinite;
  }
  .Star-container {
    /* background-color: #202432; */
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
  }
  .star {
    position: absolute;
    width: 3px;
    height: 50px;    
    transform: translate(0,-70px);
    border-radius:50%;
    z-index: 0;
    animation: hyperspace 1.1s infinite;
    background: linear-gradient(135deg, #F0CE7A 20%, #CDF081 80%, #F0E91D 100%) fixed;     
  }    
  
  .star-1 {
    left: 5%;    
    animation-delay: 0.10s;
  }
  .star-2 {
    right: 20%;
    animation-delay: 0.10s;
  }
  .star-3 {
    left: 40%;
    animation-delay: 0.2s;
  }
  .star-4 {
    right: 35%;
    animation-delay: 0.2s;
  }
  .star-5 {
    right: 50%;
    animation-delay: 0.40s;
  }
  .star-6 {
    right: 60%;
    animation-delay: 0.50s;
  }
  .star-7 {
    left: 45%;
    animation-delay: 0.7s;
  }
  .star-8 {
    right: 45%;
    animation-delay: 0.7s;
  }
  .star-9 {
    right: 30%;
    animation-delay: 0.80s;
  }
  .star-10 {
    right: 40%;
    animation-delay: 0.30s;
  }
  .star-11 {
    left: 35%;
    animation-delay: 0.9s;
  }
  .star-12 {
    right: 95px;
    animation-delay: 0.8s;
  }
  .star-13 {
    left: 63%;
    animation-delay: 0.36s;
  }
  .star-14 {
    right: 10%;
    animation-delay: 0.40s;
  }
  .star-15 {
    left: 70%;
    animation-delay: 0.32s;
  }
  .star-16 {
    right: 25%;
    animation-delay: 0.55s;
  }
  
  
  @media only screen and (max-width: 600px) {
    .Left-container {
      width: 100%;
    }
    .Right-container {
      width: 100%;
    }
    .Rocket-container {
      height: 300px;
    }
    .Rocket {
      height: 300px;
    }
    .Exhaust {
      width: 35px;
    }
  }
  
  @keyframes vibration {
    0% {
      transform: translate(0,0);
    }
    50% {
      transform: translate(0px,-1px);
    }
    100% {
      transform: translate(0,0);
    }
  }
  
  @keyframes main-fire {
    0% {
      transform: translate(-50%,5px) scale(1.1,1.5);
    }
    100% {
      transform: translate(-50%,0) scale(1,1);
    }
  }
  
  @keyframes hyperspace {
    0% {
      transform: translate(0, -100px) scale(0.6, 1);
      opacity: 1;
    }
    100% {
      transform: translate(0,100vh) scale(0, 1.5);
      opacity: 0;
    }
  }